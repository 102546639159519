<template>
  <data-list
    class="content md"
    ref="dataList"
    url="/admin/bless"
    query="&sort=-id"
    entity-name="祝福"
    add-btn="新增祝福"
    hide-show-detail
    :rules="rules"
    :columns="columns"
    :before-update="beforeUpdate"
    :search-key-type="{ content: 1 }"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="">
        <a-input v-model="form.content" placeholder="输入内容查询"></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="今日祝福" prop="content">
        <a-textarea v-model="form.content" placeholder="请输入今日祝福"></a-textarea>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { getUserId } from "../../../common/js/storage";

export default {
  name: "CheckinWish",
  data() {
    return {
      columns: [{ title: "今日祝福", dataIndex: "content" }],
      rules: {
        content: [{ required: true, message: "请输入今日祝福", trigger: "blur" }],
      },
    };
  },
  methods: {
    beforeUpdate(form, callback) {
      form.user_id = getUserId();
      callback(form);
    },
  },
};
</script>
